import Popup from 'reactjs-popup';
import { parseISO, format } from 'date-fns';

const UsersItem = ({userDetails, renderCourseAccessPopUp, renderChangePasswordPopup}) => {
    const {username, email, phone, createdAt, course_access} = userDetails;

    const formatDate = (date) => {
        const dbDate = parseISO(date);
        const formattedDate = format(dbDate, 'dd-MMM-yyyy hh:mm a');
        return formattedDate;
    }

    return (
        <tr className="users-table-data-row">
            <td data-cell='username' className="users-table-data">{username}</td>
            <td data-cell='email' className="users-table-data">{email}</td>
            <td data-cell='Phone' className="users-table-data">{phone}</td>
            <td data-cell='Created At' className="users-table-data">{formatDate(createdAt)}</td>
            <td data-cell='Course Access' className="users-table-data">
                <Popup
                    trigger={<button className="block-user-button">{course_access === 0 ? "Grant" : "Remove"}</button>}
                    modal
                >
                    {close => (
                    <div className="modal">
                        {renderCourseAccessPopUp(close, email, course_access)}
                    </div>
                    )}
                </Popup>
            </td>
            <td data-cell='Change Password' className="users-table-data">
                <Popup
                    trigger={<button className="block-user-button">Change</button>}
                    modal
                >
                    {close => (
                    <div className="modal">
                        {renderChangePasswordPopup(close, email)}
                    </div>
                    )}
                </Popup>
            </td>
        </tr>
    )
}

export default UsersItem;