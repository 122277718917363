import { Link, Navigate } from 'react-router-dom';
import { ImUsers } from "react-icons/im";
import { LuClipboardEdit } from "react-icons/lu";
import { BsClipboard2Check } from "react-icons/bs";
import { AiFillControl } from "react-icons/ai";
import { BsCalendar2Date } from "react-icons/bs";
import Popup from 'reactjs-popup';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { Oval } from 'react-loader-spinner';
import './style.css'

const AdminPage = () => {

    const [expiryDate, setExpiryDate] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e) => {
        setExpiryDate(e.target.value);
    }



    const updateExpiryDate = async (close) => {
        if(!expiryDate) return setError('Please select a date');
        setError('');
        setLoading(true);

        try {
            const url = process.env.REACT_APP_API_URL + '/admin/update-expiry-date';
            const options = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${Cookies.get('jwt_token')}`
                },
                body: JSON.stringify({expiryDate})
            };
            const response = await fetch(url, options);
            const data = await response.json();
            if(response.ok === true) {
                setExpiryDate('');
                setError('');
                close();
                alert(data.success);
            } else {
                setError(data.error);
            }
        } catch (error) {
            setError('An error occurred. Please try again later.', error);
        }
        setLoading(false);
    }

    const renderCourseExpiryPopUp = (close) => {
        return (
            <div className='faculty-popup'>
                <button className='faculty-popup-close' onClick={close} disabled={loading}>&times;</button>
                <h1 className='faculty-popup-heading'>Update Test Expiry</h1>
                <div className='faculty-popup-form'>
                    <label className='faculty-popup-label' htmlFor='date'>Expiry Date</label>
                    <input className='faculty-popup-input' type='date' id='date' name='date' value={expiryDate} onChange={handleInputChange} required />
                    {error && <p className='faculty-popup-error'>{error}</p>}
                    <button className='faculty-popup-button' disabled={loading} type='submit' onClick={() => updateExpiryDate(close)}>
                        {loading ? 
                            <Oval
                                visible={true}
                                height="20"
                                width="20"
                                color="#41B06E"
                                strokeWidth="4"
                                ariaLabel="oval-loading"
                                wrapperStyle={{}}
                                secondaryColor="#fff"
                                wrapperClass=""
                            /> : 
                        'Update'}
                    </button>
                </div>
            </div>
        )
    }

    if(Cookies.get('admin') === undefined) return (<Navigate to='/' />);

    return (
        <div className="admin-page-con">
            <div className='admin-page-content'>
                <h1 className='admin-page-title'>Welcome to <span className='admin-span'> Admin</span> Portal</h1>
                <div className='admin-page-btn-con'>
                    <Link to='/admin/users' className='admin-page-btn'>
                        <ImUsers className='admin-page-icon' />
                        <p className='admin-page-btn-text'>View All Users</p>
                    </Link>
                    <Link to='/admin/tests' className='admin-page-btn'>
                        <LuClipboardEdit className='admin-page-icon' />
                        <p className='admin-page-btn-text'>View All Tests</p>
                    </Link>
                    <Link to='/admin/submissions' className='admin-page-btn'>
                        <BsClipboard2Check className='admin-page-icon' />
                        <p className='admin-page-btn-text'>View All Submissions</p>
                    </Link>
                    <Link to='/admin/faculty' className='admin-page-btn'>
                        <AiFillControl className='admin-page-icon' />
                        <p className='admin-page-btn-text'>Control Homepage</p>
                    </Link>
                    
                    <Popup
                        trigger={
                            <button type='button' className='admin-page-btn'>
                                <BsCalendar2Date className='admin-page-icon' />
                                <p className='admin-page-btn-text'>Update Course Expiry</p>
                            </button>
                        }
                        modal
                    >
                        {close => (
                        <div className="modal">
                            {renderCourseExpiryPopUp(close)}
                        </div>
                        )}
                    </Popup>
                </div>
            </div>
            <div className='admin-page-bg'>
                <img src='/admin-bg.png' draggable={false} alt='admin-bg' className='admin-bg-img' />
            </div>
        </div>
    );
}

export default AdminPage;