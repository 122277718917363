import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { format, parseISO } from 'date-fns';
import { MutatingDots } from 'react-loader-spinner';
import { Navigate } from 'react-router-dom';

const apiStatusConstants = {
    initial: 'INITIAL',
    loading: 'LOADING',
    success: 'SUCCESS',
    failure: 'FAILURE'
}

const AdminSubmissionsPage = () => {

    const [submissions, setSubmissions] = useState([]);
    const [apiStatus, setApiStatus] = useState(apiStatusConstants.initial);

    useEffect(() => {
        fetchSubmissions();
    }, []);

    const fetchSubmissions = async () => {
        try {
            setApiStatus(apiStatusConstants.loading);
            const url = process.env.REACT_APP_API_URL + '/admin/submissions';
            const options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${Cookies.get('jwt_token')}`
                }
            }
            const response = await fetch(url, options);
            const data = await response.json();
            if (response.ok) {
                setSubmissions(data);
                setApiStatus(apiStatusConstants.success);
            } else {
                console.error('Error fetching submissions:', data);
                setApiStatus(apiStatusConstants.failure);
            }

        } catch (error) {
            console.error('Error fetching submissions:', error);
            setApiStatus(apiStatusConstants.failure);
        }
    }

    const formatDate = (date) => {
        const parsedDate = parseISO(date);
        const formattedDate = format(parsedDate, 'dd MMM yyyy hh:mm:ss a');
        return formattedDate;
    }

    const renderSubmissions = () => (
        <>
        <h1 className='submission-heading'>Submissions</h1>
        <ul className='submissions-list'>
            {
                submissions.map(submission => (
                    <li className='submission-item' key={submission.id}>
                        <img src='/submission-img.jpg' alt='submission' className='submission-image' />
                        <div className='submission-details'>
                            <p className='test-name'>{submission.test.title}</p>
                            <p className='test-duration'><span className='submission-span'>Duration: </span> {submission.test.duration_mins} minutes</p>
                            <p className='test-duration'><span className='submission-span'>Difficulty: </span> {submission.test.difficulty}</p>
                            <p className='test-duration'><span className='submission-span'>Score: </span> {submission.score}/{submission.total_score}</p>
                            <p className='test-duration'><span className='submission-span'>Submitted on: </span> {formatDate(submission.createdAt)}</p>
                            <p className='test-duration'><span className='submission-span'>Written by: </span> {submission.user.username} ({submission.user.email})</p>
                        </div>
                    </li>
                ))
            }
            
        </ul>
        </>
    )

    const renderNoSubmissions = () => (
        <div className='no-submissions-con'>
            <p className='no-submissions-text'>No submissions found.</p>
        </div>
    )

    const renderLoading = () => (
        <div className="loading-container">
            <MutatingDots
                visible={true}
                height="100"
                width="100"
                color="#41B06E"
                secondaryColor="#41B06E"
                radius="12.5"
                ariaLabel="mutating-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
            />
        </div>
    )

    const renderFailure = () => (
        <div className="exam-failure-con">
            <p className='exam-failure-text'>Failed to load submissions. Please try again later.</p>
            <button className='exam-failure-button' onClick={fetchSubmissions}>Try Again</button>
        </div>
    )

    const renderSwitch = () => {
        switch (apiStatus) {
            case apiStatusConstants.loading:
                return renderLoading();
            case apiStatusConstants.success:
                return submissions.length > 0 ? renderSubmissions() : renderNoSubmissions();
            case apiStatusConstants.failure:
                return renderFailure();
            default:
                return null;
        }
    }

    if(Cookies.get('admin') === undefined) return (<Navigate to='/' />);

    return (
        <div className='submissions-page'>
            {renderSwitch()}
        </div>
    )
}

export default AdminSubmissionsPage;