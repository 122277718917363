const FacultyCard = ({ faculty }) => {
    return (
        <li className='faculty-item'>
            <img src={faculty.image_url} alt={faculty.name} className='faculty-image1' draggable={false} />
            <div className='faculty-content'>
                <h3 className='faculty-name'>{faculty.name}</h3>
                <p className='faculty-designation'>{faculty.designation}</p>
            </div>
        </li>
    )
}

export default FacultyCard;