import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { MutatingDots } from 'react-loader-spinner';
import './style.css'
import TestItem from '../TestItem';
import PaymentPopUp from '../PaymentPopUp';

const apiStatusConstants = {
    initial: 'INITIAL',
    loading: 'LOADING',
    success: 'SUCCESS',
    failure: 'FAILURE'
}

const TestsPage = () => {

    const initialType = new URLSearchParams(window.location.search).get('type') || 'Math Methods';
    const [tests, setTests] = useState([]);
    const [showPaymentPopUp, setShowPaymentPopUp] = useState(false);
    const [apiStatus, setApiStatus] = useState(apiStatusConstants.initial);
    const [testType, setTestType] = useState(initialType);

    useEffect(() => {
        getTests();
        updateUrl(testType);
    }, []);

    const updateUrl = (type) => {
        const url = new URL(window.location.href);
        url.searchParams.set('type', type);
        window.history.pushState({}, '', url);
    };

    const getTests = async () => {
        setApiStatus(apiStatusConstants.loading);
        try {
            const url = process.env.REACT_APP_API_URL + '/test/list';
            const options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + Cookies.get('jwt_token')
                }
            }
            const response = await fetch(url, options);
            const data = await response.json();
            if(response.ok === true) {
                setTests(data);
                setApiStatus(apiStatusConstants.success);
            } else {
                console.log(data);
                setApiStatus(apiStatusConstants.failure);
            }
        } catch (error) {
            console.log(error);
            setApiStatus(apiStatusConstants.failure);
        }
    }

    const navigate = useNavigate();

    const checkExamAccess = async (id) => {
        try {
            const url = process.env.REACT_APP_API_URL + '/exam/access';
            const options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${Cookies.get('jwt_token')}`
                }
            }
            const response = await fetch(url, options);
            const data = await response.json();
            if (response.ok === true) {
                if (data.access) {
                    navigate(`/tests/${id}`);
                } else {
                    setShowPaymentPopUp(true);
                }
            } else {
                alert(data.message);
            }
        } catch (error) {
            alert('Error checking exam access:', error);
        }
    }

    const onTestTypeChange = (type) => {
        setTestType(type);
        updateUrl(type)
    }

    const filterTests = tests.filter(test => test.type === testType);

    const renderTests = () => (
        <ul className='tests-list'>
            {
                filterTests.map(test => {
                    return <TestItem key={test.id} test={test} checkExamAccess={checkExamAccess} />
                })
            }
        </ul>
    )

    const renderNoTests = () => (
        <div className='no-submissions-con'>
            <p className='no-submissions-text'>No tests available</p>
        </div>
    )

    const renderLoading = () => (
        <div className="loading-container">
            <MutatingDots
                visible={true}
                height="100"
                width="100"
                color="#41B06E"
                secondaryColor="#41B06E"
                radius="12.5"
                ariaLabel="mutating-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
            />
        </div>
    )

    const renderFailure = () => (
        <div className="exam-failure-con">
            <p className='exam-failure-text'>Failed to load tests, please try again later</p>
            <button className='exam-failure-button' onClick={getTests}>Try Again</button>
        </div>
    )

    const renderSwitch = () => {
        switch (apiStatus) {
            case apiStatusConstants.loading:
                return renderLoading();
            case apiStatusConstants.success:
                return tests.length > 0 ? renderTests() : renderNoTests();
            case apiStatusConstants.failure:
                return renderFailure();
            default:
                return null;
        }
    }

    return (
        <div className='test-page-container'>
            <div className='test-page-header'>
                <h1 className='tests-heading'>Tests</h1>
                <div className='test-page-header-right'>
                    <button className={`test-page-header-button ${testType === 'Math Methods' ? "active-test-page-header-button" : ""}`} onClick={() => onTestTypeChange("Math Methods")} >Math Methods</button>
                    <button className={`test-page-header-button ${testType === 'Math Content' ? "active-test-page-header-button" : ""}`} onClick={() => onTestTypeChange("Math Content")} >Math Content</button>
                    <button className={`test-page-header-button ${testType === 'EAMCET' ? "active-test-page-header-button" : ""}`} onClick={() => onTestTypeChange("EAMCET")} >EAMCET</button>
                </div>
            </div>
            {renderSwitch()}
            {
                showPaymentPopUp && <PaymentPopUp setShowPaymentPopUp={setShowPaymentPopUp} />
            }
        </div>
    );
}

export default TestsPage;