import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { MutatingDots } from 'react-loader-spinner';
import './style.css';

const apiStatusConstants = {
  initial: 'INITIAL',
  loading: 'LOADING',
  success: 'SUCCESS',
  failure: 'FAILURE'
}

const PaymentPopUp = ({ setShowPaymentPopUp }) => {

  const [content, setContent] = useState({});
  const [apiStatus, setApiStatus] = useState(apiStatusConstants.initial);

  useEffect(() => {
    fetchContent();
  }, []);

  const fetchContent = async () => {
    try {
        setApiStatus(apiStatusConstants.loading);
        const url = process.env.REACT_APP_API_URL + '/admin/faculty';
        const options = {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${Cookies.get('jwt_token')}`
          }
      }
        const response = await fetch(url, options);
        const data = await response.json();
        if (response.ok === true) {
            setContent(data.homepageContent[0]);
            setApiStatus(apiStatusConstants.success);
        } else {
            setApiStatus(apiStatusConstants.failure);
        }
    } catch (error) {
        setApiStatus(apiStatusConstants.failure);
    }
  }

  const renderPaymentDetails = () => (
    <>
      <p className='dashboard-text' style={{marginTop: "25px", marginBottom: "0px"}}>Your one-stop destination for all your mathematical needs.</p>
      <p className='dashboard-text price-text'>తెలంగాణ - స్కూల్ అసిస్టెంట్ గణితం మెథడ్ ప్రాక్టీస్ బిట్స్ కేవలం - <span className='actual-price'> ₹{content.price}/-</span> <span className='price'> ₹{content.offer_price}/- </span></p>
      <div className='dashboard-content'>
          <img src={content.scanner_url} alt='dashboard' className='upi-image' draggable={false} />
          <div className='upi-container'>
              <h3 className='dashboard-upi-id'>UPI ID: </h3>
              <p className='dashborad-upi-text'>{content.upi_id}</p>
          </div>
      </div>
      <p className='dashboard-text'>Please make the payment of <span className='price'> ₹{content.offer_price}/- </span>rupees to the above UPI ID to get access to the Tests.</p>
      <p className='dashboard-text'>Once the payment is done, please send the screenshot of the payment to the following email address: <span className='price'> {content.email} </span> </p>
      <p className='dashboard-text'>You can also send the screenshot of the payment to the following WhatsApp number: <span className='price'> {content.phone}</span></p>
      <p className='dashboard-text'>You will receive the Test Access within 24 hours of the payment.</p>
    </>
  )

  const renderLoading = () => (
    <div className="loading-container">
        <MutatingDots
            visible={true}
            height="100"
            width="100"
            color="#41B06E"
            secondaryColor="#41B06E"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
        />
    </div>
  )

  const renderFailure = () => (
    <div className="exam-failure-con">
        <p className='exam-failure-text'>Failed to load the Payment Details. Please try again later.</p>
        <button className='exam-failure-button' onClick={fetchContent}>Try Again</button>
    </div>
  )

  const renderSwitch = () => {
    switch (apiStatus) {
        case apiStatusConstants.loading:
            return renderLoading();
        case apiStatusConstants.success:
            return renderPaymentDetails();
        case apiStatusConstants.failure:
            return renderFailure();
        default:
            return null;
    }
  }

  return (
    <div className="payment-popup">
        <div className="payment-popup-overlay" onClick={() => setShowPaymentPopUp()}></div>
        <div className="payment-popup-content">
            <button className='close-button' onClick={() => setShowPaymentPopUp()}>&times;</button>
            {renderSwitch()}
        </div>
    </div>
  );
}

export default PaymentPopUp;