import Popup from 'reactjs-popup'
import Cookies from 'js-cookie'
import { useState } from 'react'
import { Oval } from 'react-loader-spinner'
import { Navigate } from 'react-router-dom'
import QuestionForm from '../QuestionForm'
import './style.css'

const CreateTestPage = () => {

    const [questinError, setQuestionError] = useState('');
    const [loading, setLoading] = useState(false);
    const [question, setQuestion] = useState({
        question_text: "",
        option_a: "",
        option_b: "",
        option_c: "",
        option_d: "",
        correct_option: "A"
    });
    const [testDetailsAndQues, setTestDetailsAndQues] = useState({
        testDetails:  {
            title: "",
            description: "",
            duration_mins: "",
            difficulty: "Easy",
            type: "Math Methods"
        },
        questions: []
    });

    const handletestDetailsChange = (e) => {
        setTestDetailsAndQues({
            ...testDetailsAndQues,
            testDetails: {
                ...testDetailsAndQues.testDetails,
                [e.target.name]: e.target.value
            }
        });
    }

    const handleQuestionChange = (e) => {
        setQuestion({
            ...question,
            [e.target.name]: e.target.value
        });
    }

    const addQuestion = (close) => {
        if(!question.question_text || !question.option_a || !question.option_b || !question.option_c || !question.option_d || !question.correct_option) {
            setQuestionError('All fields are required');
            return;
        } else if (!question.question_text || question.question_text.length < 6) {
            setQuestionError('Question must be at least 6 characters long');
            return
        } else if (!question.option_a || question.option_a.length < 1) {
            setQuestionError('Option A must be at least 1 character long');
            return
        } else if (!question.option_b || question.option_b.length < 1) {
            setQuestionError('Option B must be at least 1 character long');
            return
        } else if (!question.option_c || question.option_c.length < 1) {
            setQuestionError('Option C must be at least 1 character long');
            return
        } else if (!question.option_d || question.option_d.length < 1) {
            setQuestionError('Option D must be at least 1 character long');
            return
        } else if (!question.correct_option || question.correct_option.length === 0) {
            setQuestionError('Please select the correct option');
            return
        }

        setQuestionError('');
        setTestDetailsAndQues({
            ...testDetailsAndQues,
            questions: [...testDetailsAndQues.questions, question]
        });
        setQuestion({
            question_text: "",
            option_a: "",
            option_b: "",
            option_c: "",
            option_d: "",
            correct_option: "A"
        });
        close();
    }

    const removeQuestion = (index) => {
        const newQuestions = testDetailsAndQues.questions.filter((question, i) => i !== index);
        setTestDetailsAndQues({
            ...testDetailsAndQues,
            questions: newQuestions
        });
    }

    const createTest = async () => {
        if(!testDetailsAndQues.testDetails.title || !testDetailsAndQues.testDetails.description || !testDetailsAndQues.testDetails.duration_mins || !testDetailsAndQues.testDetails.difficulty) {
            setQuestionError('All fields are required');
            return;
        } else if (!testDetailsAndQues.testDetails.title || testDetailsAndQues.testDetails.title.length < 3) {
            setQuestionError('Test name must be at least 3 characters long');
            return
        } else if (!testDetailsAndQues.testDetails.description || testDetailsAndQues.testDetails.description.length < 15) {
            setQuestionError('Test description must be at least 15 characters long');
            return
        } else if (!testDetailsAndQues.testDetails.duration_mins || testDetailsAndQues.testDetails.duration_mins < 1) {
            setQuestionError('Test duration must be at least 1 minute');
            return
        } else if (!testDetailsAndQues.testDetails.type || testDetailsAndQues.testDetails.type.length === 0) {
            setQuestionError('Please select a type for the test');
            return
        } else if (!testDetailsAndQues.testDetails.difficulty || testDetailsAndQues.testDetails.difficulty.length === 0) {
            setQuestionError('Please select a difficulty level for the test');
            return
        } else if (testDetailsAndQues.questions.length === 0) {
            setQuestionError('Please add at least one question');
            return
        }
        setQuestionError('');
        
        const url = process.env.REACT_APP_API_URL + '/test/create';
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + Cookies.get('jwt_token')
            },
            body: JSON.stringify(testDetailsAndQues)
        }
        
        setLoading(true);
        try {
            const response = await fetch(url, options);
            const data = await response.json();
            if(response.ok === true) {
                alert(data.success);
                setTestDetailsAndQues({
                    testDetails:  {
                        title: "",
                        description: "",
                        duration_mins: "",
                        difficulty: "Easy",
                        type: "Math Methods"
                    },
                    questions: []
                });
                setQuestion({
                    question_text: "",
                    option_a: "",
                    option_b: "",
                    option_c: "",
                    option_d: "",
                    correct_option: "A"
                });
            } else {
                alert(data.error);
            }
        } catch (error) {
            alert('An error occurred while creating the test');
        }
        setLoading(false);
    }

    if(Cookies.get('admin') === undefined) return (<Navigate to='/' />);

    return (
        <div className='create-test-page-container'>
            <h1 className='create-test-heading'>Create New Test</h1>
            <div className='create-test-form'>
                <h2 className='create-test-subheading'>Test Details</h2>
                <div className='create-test-form-group'>
                    <label htmlFor='test-name' className='create-test-label'>Test Name:</label>
                    <input type='text' id='test-name' className='create-test-input' placeholder='your answer' name='title' value={testDetailsAndQues.testDetails.title} onChange={handletestDetailsChange} />
                </div>
                <div className='create-test-form-group'>
                    <label htmlFor='test-desc' className='create-test-label'>Test Description:</label>
                    <textarea id='test-desc' className='create-test-textarea' placeholder='your answer' name='description' value={testDetailsAndQues.testDetails.description} onChange={handletestDetailsChange}></textarea>
                </div>
                <div className='create-test-form-group'>
                    <label htmlFor='test-duration' className='create-test-label'>Test Duration (in minutes):</label>
                    <input type='number' id='test-duration' className='create-test-input' placeholder='your answer' name='duration_mins' value={testDetailsAndQues.testDetails.duration_mins} onChange={handletestDetailsChange}/>
                </div>
                <div className='create-test-form-group'>
                    <label htmlFor='test-type' className='create-test-label'>Test Type:</label>
                    <select id='test-type' className='create-test-input' name='type' value={testDetailsAndQues.testDetails.type} onChange={handletestDetailsChange}>
                        <option value='Math Methods'>Math Methods</option>
                        <option value='Math Content'>Math Content</option>
                        <option value='EAMCET'>EAMCET</option>
                    </select>
                </div>
                <div className='create-test-form-group'>
                    <label htmlFor='test-difficulty' className='create-test-label'>Test Difficulty:</label>
                    <select id='test-difficulty' className='create-test-input' name='difficulty' value={testDetailsAndQues.testDetails.difficulty} onChange={handletestDetailsChange}>
                        <option value='easy'>Easy</option>
                        <option value='medium'>Medium</option>
                        <option value='hard'>Hard</option>
                    </select>
                </div>
            </div>

            <ul className='questions-list'>
                {
                    testDetailsAndQues.questions.map((question, index) => {
                        return <li key={index} className='question-list-item'>
                            <div className='question-list-head-button-con'>
                                <h3 className='question-list-heading'><span className='question-list-heading-span'>{index + 1}. </span> {question.question_text}</h3>
                                <div className='question-list-button-con'>
                                    <button className='question-list-button' onClick={() => removeQuestion(index)}>Delete</button>
                                </div>
                            </div>
                            <p className='question-list-text'><span className='question-list-text-span'>A: </span> {question.option_a}</p>
                            <p className='question-list-text'><span className='question-list-text-span'>B: </span> {question.option_b}</p>
                            <p className='question-list-text'><span className='question-list-text-span'>C: </span> {question.option_c}</p>
                            <p className='question-list-text'><span className='question-list-text-span'>D: </span> {question.option_d}</p>
                            <p className='question-list-text'><span className='question-list-text-span'>Correct Option: </span> {question.correct_option}</p>
                        </li>
                    })
                }
            </ul>
            {questinError && <p className='question-error' style={{marginLeft: "10%"}} >{questinError}</p>}
            <div className='add-question-button-container'>
                <Popup
                    trigger={<button className="add-question-button">Add Question</button>}
                    modal
                >
                    {close => (
                    <div className="question-modal">
                        <QuestionForm close={close} handleQuestionChange={handleQuestionChange} question={question} addQuestion={addQuestion} questinError={questinError} />
                    </div>
                    )}
                </Popup>
                <button className='add-question-button' onClick={createTest}>
                    {
                    loading ?
                        <Oval
                        visible={true}
                        height="20"
                        width="20"
                        color="#41B06E"
                        strokeWidth="4"
                        ariaLabel="oval-loading"
                        wrapperStyle={{}}
                        secondaryColor="#fff"
                        wrapperClass=""
                      /> : 
                      'Create Test'
                    }
                </button>
            </div>
        </div>
    )
}

export default CreateTestPage;