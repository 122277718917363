import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { MutatingDots } from 'react-loader-spinner';
import FacultyCard from '../FacultyCard';
import './style.css'

const apiStatusConstants = {
    initial: 'INITIAL',
    loading: 'LOADING',
    success: 'SUCCESS',
    failure: 'FAILURE'
}

const HomePage = () => {

    const [facultyCards, setFacultyCards] = useState([]);
    const [apiStatus, setApiStatus] = useState(apiStatusConstants.initial);
    const [content, setContent] = useState({});

    useEffect(() => {
        getFacultyCards();
    }, []);

    const getFacultyCards = async () => {
        try {
            setApiStatus(apiStatusConstants.loading);
            const url = process.env.REACT_APP_API_URL + '/admin/faculty';
            const options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${Cookies.get('jwt_token')}`
                }
            }
            const response = await fetch(url, options);
            const data = await response.json();
            if (response.ok === true) {
                setFacultyCards(data.faculty);
                setContent(data.homepageContent[0]);
                setApiStatus(apiStatusConstants.success);
            } else {
                setApiStatus(apiStatusConstants.failure);
            }
        } catch (error) {
            setApiStatus(apiStatusConstants.failure);
        }
    }

    const renderHome = () => (
        <>
        <h1 className='home-heading'>
            ఉస్మానియా యూనివర్సిటీ పూర్వ విద్యార్థుల మరియు మాథమెటిక్స్ ర్యాంకర్స్ యొక్క ఆలోచన అభిప్రాయాలు క్రోడికరించి రూపొందించిన <br />
            <span className='home-span'> “హైపాటియా మాథమెటిక్స్ అకాడమీ”</span>
        </h1>
        <ul className='faculty-list'>
            {
                facultyCards.length > 0 &&
                facultyCards.map((faculty) => <FacultyCard faculty={faculty} key={faculty.id} />)
            }
        </ul>
        
        <h1 className='home-heading'>
            వీరందరు గత పది సంవత్సరాలనుండి ఉపాధ్యాయ వృత్తిలో అనేక ఒడిదుడుకులు  ఎదుర్కోని గతంలో జరిగిన గురుకుల పరీక్ష గణితం మెథడాలజీలో 75 పైగా మార్కులు పొంది రెండు, మూడు ఉద్యోగాలు సాధించిన వీరి ఆలోచనలు పరిగణలోకి తీసుకొని ఈ అకాడమీ స్థాపించము. కావున ఇది కాబోయే ఉపాధ్యాయులకు అన్ని విధాలుగా ఉపయోగపడుతుందని ఆశిస్తున్నాము.
        </h1>

        <Link to='/tests' className='home-button'>Go to Tests</Link>

        <p className='dashboard-text' style={{marginTop: "25px", marginBottom: "0px"}}>Your one-stop destination for all your mathematical needs.</p>
        <p className='dashboard-text price-text'>తెలంగాణ - స్కూల్ అసిస్టెంట్ గణితం మెథడ్ ప్రాక్టీస్ బిట్స్ కేవలం - <span className='actual-price'> ₹{content.price}/-</span> <span className='price'> ₹{content.offer_price}/- </span></p>
        <div className='dashboard-content'>
            <img src={content.scanner_url} alt='dashboard' className='upi-image' draggable={false} />
            <div className='upi-container'>
                <h3 className='dashboard-upi-id'>UPI ID: </h3>
                <p className='dashborad-upi-text'>{content.upi_id}</p>
            </div>
        </div>
        <p className='dashboard-text'>Please make the payment of <span className='price'> ₹{content.offer_price}/- </span>rupees to the above UPI ID to get access to the Tests.</p>
        <p className='dashboard-text'>Once the payment is done, please send the screenshot of the payment to the following email address: <span className='price'> {content.email} </span> </p>
        <p className='dashboard-text'>You can also send the screenshot of the payment to the following WhatsApp number: <span className='price'> {content.phone}</span></p>
        <p className='dashboard-text'>You will receive the Test Access within 24 hours of the payment.</p>
        </>
    )
    
    const renderLoading = () => (
        <div className="loading-container">
            <MutatingDots
                visible={true}
                height="100"
                width="100"
                color="#41B06E"
                secondaryColor="#41B06E"
                radius="12.5"
                ariaLabel="mutating-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
            />
        </div>
    )

    const renderFailure = () => (
        <div className="exam-failure-con">
            <p className='exam-failure-text'>Failed to load the page. Please try again later.</p>
            <button className='exam-failure-button' onClick={getFacultyCards}>Try Again</button>
        </div>
    )

    const renderSwitch = () => {
        switch (apiStatus) {
            case apiStatusConstants.loading:
                return renderLoading();
            case apiStatusConstants.success:
                return renderHome();
            case apiStatusConstants.failure:
                return renderFailure();
            default:
                return null;
        }
    }

    return (
        <div className='dashboard-container'>
            {renderSwitch()}
        </div>
    );
}

export default HomePage;