import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const withPageLeaveConfirmation = (WrappedComponent) => {
  const ComponentWithConfirmation = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
      const handlePageLeave = (event) => {
        event.preventDefault();
        event.returnValue = '';

        const confirmationMessage = 'Are you sure you want to leave this page?';
        if (window.confirm(confirmationMessage)) {
          navigate(event.currentTarget.href);
        }
      };

      window.addEventListener('beforeunload', handlePageLeave);

      return () => {
        window.removeEventListener('beforeunload', handlePageLeave);
      };
    }, [navigate]);

    return <WrappedComponent {...props} />;
  };

  return ComponentWithConfirmation;
};

export default withPageLeaveConfirmation;
