

const TestItem = ({ test, checkExamAccess }) => {
    return (
        <li className='test-list-item'>
            <div className='test-list-image'>
                <img src='/test-icon.avif' alt='test' className='test-image' draggable={false} />
            </div>
            <div className='test-list-details'>
                <h3 className='test-list-heading'>{test.title}</h3>
                <p className='test-list-text'>{test.description}</p>
                <div className='test-list-tags'>
                    <p className='test-list-duration'>Duration: <span className='test-span'> {test.duration_mins} minutes</span></p>
                    <p className='test-list-duration'>Difficulty: <span className='test-span'> {test.difficulty}</span></p>
                </div>

                <div className='test-list-tags'>
                    <p className='test-list-duration'>Questions: <span className='test-span'> {test.questionsCount}</span></p>
                    <p className='test-list-duration'>Score: <span className='test-span'> {test.questionsCount}</span></p>
                </div>
                <p className='test-list-duration'>Type: <span className='test-span'>{test.type}</span></p>
                <button className='test-list-button' onClick={() => checkExamAccess(test.id)}>Start Test</button>
            </div>
        </li>
    )
}

export default TestItem;