import { useState, useEffect, memo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { MutatingDots } from 'react-loader-spinner';
import './style.css'

const apiStatusConstants = {
    initial: 'INITIAL',
    loading: 'LOADING',
    success: 'SUCCESS',
    failure: 'FAILURE'
}

const TestDetailsPage = () => {

    const [testDetails, setTestDetails] = useState({});
    const [apiStatus, setApiStatus] = useState(apiStatusConstants.initial);

    useEffect(() => {
        getTestDetails();
        checkExamAccess();
    }, []);

    const { id } = useParams();
    const navigate = useNavigate();

    const getTestDetails = async () => {
        try {
            setApiStatus(apiStatusConstants.loading);
            const url = process.env.REACT_APP_API_URL + `/exam/details/${id}`;
            const options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${Cookies.get('jwt_token')}`
                }
            }
            const response = await fetch(url, options);
            const data = await response.json();
            if (response.ok === true) {
                setTestDetails(data);
                setApiStatus(apiStatusConstants.success);
            } else {
                alert(data.error);
                setApiStatus(apiStatusConstants.failure);
            }
        }
        catch (error) {
            setApiStatus(apiStatusConstants.failure);
        }
    }

    const checkExamAccess = async () => {
        try {
            const url = process.env.REACT_APP_API_URL + '/exam/access';
            const options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${Cookies.get('jwt_token')}`
                }
            }
            const response = await fetch(url, options);
            const data = await response.json();
            if (response.ok === true) {
                if (data.access) {
                    return true
                } else {
                    navigate(`/tests`);
                }
            } else {
                alert(data.message);
            }
        } catch (error) {
            alert('Error checking exam access:', error);
        }
    }

    const startTest = async () => {
        navigate(`/exam/${id}`, { replace: true });
        handleFullScreen();
    }


    const handleFullScreen = () => {
        const element = document.documentElement;
        if (document.fullscreenElement) {
            document.exitFullscreen();
        } else {
            element.requestFullscreen();
        }
    }

    const renderTestDetails = () => (
        <div className='test-details-page-content'>
            <h1 className='test-details-page-title'>Test Details</h1>
            <div className='test-details-page-info'>
                <h2 className='test-details-page-info-title'>Test Name:</h2>
                <p className='test-details-page-info-text'>{testDetails.title}</p>
            </div>
            <div className='test-details-page-info'>
                <h2 className='test-details-page-info-title'>Test Description:</h2>
                <p className='test-details-page-info-text'>{testDetails.description}</p>
            </div>
            <div className='test-details-page-info'>
                <h2 className='test-details-page-info-title'>Test Duration:</h2>
                <p className='test-details-page-info-text'>{testDetails.duration_mins} mins</p>
            </div>
            <div className='test-details-page-info'>
                <h2 className='test-details-page-info-title'>Difficulty Level:</h2>
                <p className='test-details-page-info-text'>{testDetails.difficulty}</p>
            </div>
            <div className='test-details-page-info'>
                <h2 className='test-details-page-info-title'>Number of Questions:</h2>
                <p className='test-details-page-info-text'>{testDetails.no_of_questions}</p>
            </div>
            <div className='test-details-page-info'>
                <h2 className='test-details-page-info-title'>Type of Questions:</h2>
                <p className='test-details-page-info-text'>MCQs</p>
            </div>
            <div className='test-details-page-info'>
                <h2 className='test-details-page-info-title'>Score:</h2>
                <p className='test-details-page-info-text'>{testDetails.no_of_questions}</p>
            </div>
            <div className='test-details-page-info'>
                <h2 className='test-details-page-info-title'>Marking Scheme:</h2>
                <p className='test-details-page-info-text'>All questions have equal weightage. Every correct response gets +1 mark. There is no negative marking.</p>
            </div>
            <button className='test-details-page-start-button' onClick={startTest}>Start Test</button>
        </div>
    )

    const renderLoading = () => (
        <div className="loading-container">
            <MutatingDots
                visible={true}
                height="100"
                width="100"
                color="#41B06E"
                secondaryColor="#41B06E"
                radius="12.5"
                ariaLabel="mutating-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
            />
        </div>
    )

    const renderFailure = () => (
        <div className="exam-failure-con">
            <p className='exam-failure-text'>Failed to load test details. Please try again.</p>
            <button className='exam-failure-button' onClick={getTestDetails}>Try Again</button>
        </div>
    )

    const renderSwitch = () => {
        switch (apiStatus) {
            case apiStatusConstants.loading:
                return renderLoading();
            case apiStatusConstants.success:
                return renderTestDetails();
            case apiStatusConstants.failure:
                return renderFailure();
            default:
                return null;
        }
    }

    return (
        <div className='test-details-page'>
            {renderSwitch()}
        </div>
    );
}

export default memo(TestDetailsPage);