import { useState, useEffect } from 'react';
import { MutatingDots } from 'react-loader-spinner';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Popup from 'reactjs-popup';
import './style.css'

const apiStatusConstants = {
    initial: 'INITIAL',
    loading: 'LOADING',
    success: 'SUCCESS',
    failure: 'FAILURE'
}

const ControlHomepage = () => {

    const [facultyCards, setFacultyCards] = useState([]);
    const [apiStatus, setApiStatus] = useState(apiStatusConstants.initial);
    const [error, setError] = useState('');
    const [editFormType, setEditFormType] = useState(false);
    const [editFaculty, setEditFaculty] = useState({
        id: '',
        name: '',
        designation: '',
        position: '',
        image_url: ''
    });
    const [content, setContent] = useState({
        price: '',
        offer_price: '',
        scanner_url: '',
        upi_id: '',
        email: '',
        phone: ''
    });

    useEffect(() => {
        fetchFacultyCards();
    }, []);

    const fetchFacultyCards = async () => {
        try {
            setApiStatus(apiStatusConstants.loading);
            const url = process.env.REACT_APP_API_URL + '/admin/faculty';
            const options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${Cookies.get('jwt_token')}`
                }
            }
            const response = await fetch(url, options);
            const data = await response.json();
            if (response.ok) {
                setFacultyCards(data.faculty);
                setContent(data.homepageContent[0]);
                setApiStatus(apiStatusConstants.success);
            } else {
                console.error('Error fetching faculty cards:', data);
                setApiStatus(apiStatusConstants.failure);
            }

        } catch (error) {
            setApiStatus(apiStatusConstants.failure);
            console.error('Error fetching faculty cards:', error);
        }
    }

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setEditFaculty({
            ...editFaculty,
            [name]: value
        });
    }

    const handleContentChange = (event) => {
        const {name, value} = event.target;
        setContent({
            ...content,
            [name]: value
        });
    }

    const handleEditFaculty = async (close) => {
        if (!editFaculty.name || !editFaculty.designation || !editFaculty.position || !editFaculty.image_url) {
            setError('Please fill all fields');
            return;
        } else if (editFaculty.position < 1) {
            setError('Position should be greater than 0');
            return;
        } else if (!editFaculty.image_url.startsWith('http')) {
            setError('Invalid image URL, should start with http or https');
            return;
        }
        setError('');

        try {
            const url = process.env.REACT_APP_API_URL + '/admin/update-faculty-card';
            const options = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${Cookies.get('jwt_token')}`
                },
                body: JSON.stringify(editFaculty)
            }
            const response = await fetch(url, options);
            const data = await response.json();
            if (response.ok) {
                fetchFacultyCards();
                close();
            } else {
                setError('Failed to update faculty');
            }

        } catch (error) {
            console.error('Error updating faculty:', error);
            setError('Failed to update faculty');
        }
    }

    const handleDeleteFaculty = async (id) => {
        try {
            const url = process.env.REACT_APP_API_URL + `/admin/delete-faculty-card/${id}`;
            const options = {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${Cookies.get('jwt_token')}`
                }
            }
            const response = await fetch(url, options);
            const data = await response.json();
            if (response.ok) {
                fetchFacultyCards();
            } else {
                setError('Failed to delete faculty');
            }

        } catch (error) {
            console.error('Error deleting faculty:', error);
            setError('Failed to delete faculty');
        }
    }

    const handleAddFaculty = async (close) => {

        if (!editFaculty.name || !editFaculty.designation || !editFaculty.position || !editFaculty.image_url) {
            setError('Please fill all fields');
            return;
        } else if (editFaculty.position < 1) {
            setError('Position should be greater than 0');
            return;
        } else if (!editFaculty.image_url.startsWith('http')) {
            setError('Invalid image URL, should start with http or https');
            return;
        }
        setError('');

        try {
            const url = process.env.REACT_APP_API_URL + '/admin/add-faculty-card';
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${Cookies.get('jwt_token')}`
                },
                body: JSON.stringify(editFaculty)
            }
            const response = await fetch(url, options);
            const data = await response.json();
            if (response.ok) {
                fetchFacultyCards();
                close();
            } else {
                setError('Failed to add faculty');
            }

        } catch (error) {
            console.error('Error adding faculty:', error);
            setError('Failed to add faculty');
        }
    }

    const handleUpdateContent = async (close) => {
        const emailRegex = /\S+@\S+\.\S+/;
        if (!content.price || !content.offer_price || !content.scanner_url || !content.upi_id || !content.email || !content.phone) {
            setError('Please fill all fields');
            return;
        } else if (content.price < 0) {
            setError('Price should be greater than 0');
            return;
        } else if (content.offer_price < 0 || content.offer_price > content.price) {
            setError('Offer price should be greater than 0 and less than the actual price');
            return;
        } else if (!content.scanner_url.startsWith('http')) {
            setError('Invalid scanner URL, should start with http or https');
            return;
        } else if (!content.upi_id || content.upi_id.length < 3) {
            setError('UPI ID must be at least 3 characters long');
            return;
        } else if (!content.email || !emailRegex.test(content.email)) {
            setError('Invalid email');
            return;
        } else if (!content.phone || content.phone.length !== 10) {
            setError('Phone number must be 10 digits long');
            return;
        }
        setError('');

        try {
            const url = process.env.REACT_APP_API_URL + '/admin/update-homepage-content';
            const options = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${Cookies.get('jwt_token')}`
                },
                body: JSON.stringify(content)
            }
            const response = await fetch(url, options);
            const data = await response.json();
            if (response.ok) {
                close();
            } else {
                setError('Failed to update content');
            }

        } catch (error) {
            console.error('Error updating content:', error);
            setError('Failed to update content');
        }
    }

    const renderUpdateContentPopUp = (close) => {
        return (
            <div className='faculty-popup'>
                <button className='faculty-popup-close' onClick={close}>&times;</button>
                <h1 className='faculty-popup-heading'>Update Content</h1>
                <div className='faculty-popup-form'>
                    <label className='faculty-popup-label' htmlFor='price'>Price</label>
                    <input className='faculty-popup-input' type='number' id='price' name='price' placeholder='your answer' value={content.price} onChange={handleContentChange} />
                    <label className='faculty-popup-label' htmlFor='offer_price'>Offer Price</label>
                    <input className='faculty-popup-input' type='number' id='offer_price' name='offer_price'  placeholder='your answer' value={content.offer_price} onChange={handleContentChange}/>
                    <label className='faculty-popup-label' htmlFor='scanner_url'>Scanner URL</label>
                    <input className='faculty-popup-input' type='text' id='scanner_url' name='scanner_url'  placeholder='your answer' value={content.scanner_url} onChange={handleContentChange}/>
                    <label className='faculty-popup-label' htmlFor='upi_id'>UPI ID</label>
                    <input className='faculty-popup-input' type='text' id='upi_id' name='upi_id'  placeholder='your answer' value={content.upi_id} onChange={handleContentChange}/>
                    <label className='faculty-popup-label' htmlFor='email'>Email</label>
                    <input className='faculty-popup-input' type='text' id='email' name='email'  placeholder='your answer' value={content.email} onChange={handleContentChange}/>
                    <label className='faculty-popup-label' htmlFor='phone'>Phone</label>
                    <input className='faculty-popup-input' type='text' id='phone' name='phone'  placeholder='your answer' value={content.phone} onChange={handleContentChange}/>
                    {error && <p className='faculty-popup-error'>{error}</p>}
                    <button className='faculty-popup-button' type='button'  onClick={() => handleUpdateContent(close)}>Update</button>
                </div>
            </div>
        )
    }

    const renderEditFacultyPopUp = (close) => {
        return (
            <div className='faculty-popup'>
                <button className='faculty-popup-close' onClick={close}>&times;</button>
                <h1 className='faculty-popup-heading'>{editFormType ? "Edit" : "Add"} Faculty</h1>
                <div className='faculty-popup-form'>
                    <label className='faculty-popup-label' htmlFor='name'>Name</label>
                    <input className='faculty-popup-input' type='text' id='name' name='name' placeholder='your answer' value={editFaculty.name} onChange={handleInputChange} />
                    <label className='faculty-popup-label' htmlFor='designation'>Designation</label>
                    <input className='faculty-popup-input' type='text' id='designation' name='designation'  placeholder='your answer' value={editFaculty.designation} onChange={handleInputChange}/>
                    <label className='faculty-popup-label' htmlFor='position'>Order</label>
                    <input className='faculty-popup-input' type='number' id='position' name='position'  placeholder='your answer' value={editFaculty.position} onChange={handleInputChange}/>
                    <label className='faculty-popup-label' htmlFor='image'>Image URL</label>
                    <input className='faculty-popup-input' type='text' id='image' name='image_url'  placeholder='your answer' value={editFaculty.image_url} onChange={handleInputChange}/>
                    {error && <p className='faculty-popup-error'>{error}</p>}
                    <button className='faculty-popup-button' type='button'  onClick={editFormType ? () => handleEditFaculty(close) : () => handleAddFaculty(close)}>{editFormType ? "Save" : "Add"}</button>
                </div>
            </div>
        )
    }

    const renderFacultyCards = () => (
        <>
        <ul className='submissions-list' style={{width: '100%'}}>
            {
                facultyCards.map(faculty => (
                    <li className='submission-item faculty-item2' key={faculty.id}>
                        <img src={faculty.image_url} alt='submission' className='faculty-image' />
                        <div className='submission-details faculty-details'>
                            <p className='test-name'>{faculty.name}</p>
                            <p className='test-duration'><span className='submission-span'>Designation: </span> {faculty.designation}</p>
                            <p className='test-duration'><span className='submission-span'>Order: </span> {faculty.position}</p>
                            <div className='faculty-buttons-con'>
                                
                                <Popup
                                    trigger={<button className='faculty-button'>Edit</button>}
                                    modal
                                    onOpen={() => {
                                        setEditFaculty(faculty)
                                        setEditFormType(true)
                                    }}
                                >
                                    {close => (
                                    <div className="modal">
                                        {renderEditFacultyPopUp(close)}
                                    </div>
                                    )}
                                </Popup>
                                <button className='faculty-button' onClick={() => handleDeleteFaculty(faculty.id)}>Delete</button>
                            </div>
                        </div>
                    </li>
                ))
            }
        </ul>
        </>
    )

    const renderNoFaculty = () => (
        <div className='no-submissions-con'>
            <p className='no-submissions-text'>No faculty available</p>
        </div>
    )

    const renderLoading = () => (
        <div className="loading-container">
            <MutatingDots
                visible={true}
                height="100"
                width="100"
                color="#41B06E"
                secondaryColor="#41B06E"
                radius="12.5"
                ariaLabel="mutating-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
            />
        </div>
    )

    const renderFailure = () => (
        <div className="exam-failure-con">
            <p className='exam-failure-text'>Failed to load the page. Please try again later.</p>
            <button className='exam-failure-button' onClick={fetchFacultyCards}>Try Again</button>
        </div>
    )

    const renderSwitch = () => {
        switch (apiStatus) {
            case apiStatusConstants.loading:
                return renderLoading();
            case apiStatusConstants.success:
                return facultyCards.length > 0 ? renderFacultyCards() : renderNoFaculty();
            case apiStatusConstants.failure:
                return renderFailure();
            default:
                return null;
        }
    }

    if(Cookies.get('admin') === undefined) return (<Navigate to='/' />);

    return (
        <div className="control-homepage-con">
            <div className='admin-test-page-content faculty-head-con'>
                <h1 className='tests-heading' style={{marginBottom: "0px"}}>Faculty</h1>
                <div className='faculty-head-buttons-con'>
                    <Popup
                        trigger={<button className='add-test-button facutly-sub-btn'>Add New Faculty</button>}
                        modal
                        onOpen={() => {
                                setEditFaculty({
                                name: '',
                                designation: '',
                                position: '',
                                image_url: ''
                            })
                            setEditFormType(false)
                            }
                        }
                    >
                        {close => (
                        <div className="modal">
                            {renderEditFacultyPopUp(close)}
                        </div>
                        )}
                    </Popup>
                    <Popup
                        trigger={<button className='add-test-button'>Update Content</button>}
                        modal
                        onOpen={() => {
                                setEditFaculty({
                                name: '',
                                designation: '',
                                position: '',
                                image_url: ''
                            })
                            setEditFormType(false)
                            }
                        }
                    >
                        {close => (
                        <div className="modal">
                            {renderUpdateContentPopUp(close)}
                        </div>
                        )}
                    </Popup>
                </div>
            </div>
            {renderSwitch()}
        </div>
    );
}

export default ControlHomepage;