import Popup from 'reactjs-popup'
import Cookies from 'js-cookie'
import { useState, useEffect } from 'react'
import { useParams, useNavigate, Navigate } from 'react-router-dom'
import { Oval, MutatingDots } from 'react-loader-spinner'
import QuestionForm from '../QuestionForm'
import './style.css'

const apiStatusConstants = {
    initial: 'INITIAL',
    loading: 'LOADING',
    success: 'SUCCESS',
    failure: 'FAILURE'
}

const EditTestPage = () => {

    const [questinError, setQuestionError] = useState('');
    const [loading, setLoading] = useState(false);
    const [apiStatus, setApiStatus] = useState(apiStatusConstants.initial);
    const [question, setQuestion] = useState({
        question_text: "",
        option_a: "",
        option_b: "",
        option_c: "",
        option_d: "",
        correct_option: "A"
    });

    const [testDetailsAndQues, setTestDetailsAndQues] = useState({
        testDetails:  {
            title: "",
            description: "",
            duration_mins: "",
            difficulty: "Easy",
            type: "Math Methods"
        },
        questions: []
    });

    useEffect(() => {
        fetchTestDetails();
    }, []);

    const params = useParams();
    const navigate = useNavigate();

    const fetchTestDetails = async () => {
        const id = params.id;
        const url = process.env.REACT_APP_API_URL + '/test/' + id;
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + Cookies.get('jwt_token')
            }
        }
        setApiStatus(apiStatusConstants.loading);
        try {
            const response = await fetch(url, options);
            const data = await response.json();
            if(response.ok === true) {
                setTestDetailsAndQues(data);
                setApiStatus(apiStatusConstants.success);
            } else {
                setApiStatus(apiStatusConstants.failure);
                alert(data.error);
            }
        } catch (error) {
            setApiStatus(apiStatusConstants.failure);
            alert('An error occurred while fetching the test details');
        }
    }

    const handletestDetailsChange = (e) => {
        setTestDetailsAndQues({
            ...testDetailsAndQues,
            [e.target.name]: e.target.value
        });
    }

    const handleQuestionChange = (e) => {
        setQuestion({
            ...question,
            [e.target.name]: e.target.value
        });
    }

    const addQuestion = async (close) => {
        if(!question.question_text || !question.option_a || !question.option_b || !question.option_c || !question.option_d || !question.correct_option) {
            setQuestionError('All fields are required');
            return;
        } else if (!question.question_text || question.question_text.length < 6) {
            setQuestionError('Question must be at least 6 characters long');
            return
        } else if (!question.option_a || question.option_a.length < 1) {
            setQuestionError('Option A must be at least 1 character long');
            return
        } else if (!question.option_b || question.option_b.length < 1) {
            setQuestionError('Option B must be at least 1 character long');
            return
        } else if (!question.option_c || question.option_c.length < 1) {
            setQuestionError('Option C must be at least 1 character long');
            return
        } else if (!question.option_d || question.option_d.length < 1) {
            setQuestionError('Option D must be at least 1 character long');
            return
        } else if (!question.correct_option || question.correct_option.length === 0) {
            setQuestionError('Please select the correct option');
            return
        }

        const url = process.env.REACT_APP_API_URL + '/test/question/create';
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + Cookies.get('jwt_token')
            },
            body: JSON.stringify({
                ...question,
                test_id: params.id
            })
        }

        setLoading(true);
        try {
            const response = await fetch(url, options);
            const data = await response.json();
            if(response.ok === true) {
                question.id = data.id;
                setTestDetailsAndQues({
                    ...testDetailsAndQues,
                    questions: [...testDetailsAndQues.questions, question]
                });
                setQuestion({
                    question_text: "",
                    option_a: "",
                    option_b: "",
                    option_c: "",
                    option_d: "",
                    correct_option: "A"
                });
                alert(data.success);
                close();
            } else {
                alert(data.error);
            }
        } catch (error) {
            alert('An error occurred while adding the question');
        }
        setLoading(false);
    }

    const removeQuestion = async (id) => {
        const url = process.env.REACT_APP_API_URL + '/test/question/delete/' + id;
        const options = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + Cookies.get('jwt_token')
            }
        }

        setLoading(true);
        try {
            const response = await fetch(url, options);
            const data = await response.json();
            if(response.ok === true) {
                const newQuestions = testDetailsAndQues.questions.filter(question => question.id !== id);
                setTestDetailsAndQues({
                    ...testDetailsAndQues,
                    questions: newQuestions
                });
                alert(data.success);
            } else {
                alert(data.error);
            }
        } catch (error) {
            alert('An error occurred while deleting the question');
        }
        setLoading(false);
    }

    const updateTest = async () => {
        if(!testDetailsAndQues.title || !testDetailsAndQues.description || !testDetailsAndQues.duration_mins || !testDetailsAndQues.difficulty) {
            setQuestionError('All fields are required');
            return;
        } else if (!testDetailsAndQues.title || testDetailsAndQues.title.length < 3) {
            setQuestionError('Test name must be at least 3 characters long');
            return
        } else if (!testDetailsAndQues.description || testDetailsAndQues.description.length < 15) {
            setQuestionError('Test description must be at least 15 characters long');
            return
        } else if (!testDetailsAndQues.duration_mins || testDetailsAndQues.duration_mins < 1) {
            setQuestionError('Test duration must be at least 1 minute');
            return
        } else if (!testDetailsAndQues.type || testDetailsAndQues.type.length === 0) {
            setQuestionError('Please select a type for the test');
            return
        } else if (!testDetailsAndQues.difficulty || testDetailsAndQues.difficulty.length === 0) {
            setQuestionError('Please select a difficulty level for the test');
            return
        } else if (testDetailsAndQues.questions.length === 0) {
            setQuestionError('Please add at least one question');
            return
        }
        setQuestionError('');
        const id = params.id;
        const url = process.env.REACT_APP_API_URL + '/test/update/' + id;
        const options = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + Cookies.get('jwt_token')
            },
            body: JSON.stringify({
                title: testDetailsAndQues.title,
                description: testDetailsAndQues.description,
                duration_mins: testDetailsAndQues.duration_mins,
                difficulty: testDetailsAndQues.difficulty,
                type: testDetailsAndQues.type
            })
        }
        
        setLoading(true);
        try {
            const response = await fetch(url, options);
            const data = await response.json();
            if(response.ok === true) {
                alert(data.success);
            } else {
                alert(data.error);
            }
        } catch (error) {
            alert('An error occurred while updating the test');
        }
        setLoading(false);
    }

    const deleteTest = async (close) => {
        const id = params.id;
        const url = process.env.REACT_APP_API_URL + '/test/delete/' + id;
        const options = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + Cookies.get('jwt_token')
            }
        }

        setLoading(true);
        try {
            const response = await fetch(url, options);
            const data = await response.json();
            if(response.ok === true) {
                alert(data.success);
                close();
                navigate('/admin/tests', {replace: true});
            } else {
                alert(data.error);
            }
        } catch (error) {
            alert('An error occurred while deleting the test');
        }
        setLoading(false);
    }

    const renderTestDeletePopUp = (close) => (
        <div className="modal-form">
            <button className="modal-close-button" disabled={loading} onClick={close}>&times;</button>
            <p className="modal-text">Do you want to delete this test?</p>
            <div className='achieve-button-con'>
            <button className='job-details-upload-candidate-button' disabled={loading} onClick={() => deleteTest(close)}>YES</button>
            <button className='job-details-upload-candidate-button archieve-cancel-btn' disabled={loading} onClick={close}>NO</button>
            </div>
        </div>
    )

    const renderTests = () => (
        <>
        <div className='create-test-form'>
            <h2 className='create-test-subheading'>Test Details</h2>
            <div className='create-test-form-group'>
                <label htmlFor='test-name' className='create-test-label'>Test Name:</label>
                <input type='text' id='test-name' className='create-test-input' placeholder='your answer' name='title' value={testDetailsAndQues.title} onChange={handletestDetailsChange} />
            </div>
            <div className='create-test-form-group'>
                <label htmlFor='test-desc' className='create-test-label'>Test Description:</label>
                <textarea id='test-desc' className='create-test-textarea' placeholder='your answer' name='description' value={testDetailsAndQues.description} onChange={handletestDetailsChange}></textarea>
            </div>
            <div className='create-test-form-group'>
                <label htmlFor='test-duration' className='create-test-label'>Test Duration (in minutes):</label>
                <input type='number' id='test-duration' className='create-test-input' placeholder='your answer' name='duration_mins' value={testDetailsAndQues.duration_mins} onChange={handletestDetailsChange}/>
            </div>
            <div className='create-test-form-group'>
                <label htmlFor='test-type' className='create-test-label'>Test Type:</label>
                <select id='test-type' className='create-test-input' name='type' value={testDetailsAndQues.type} onChange={handletestDetailsChange}>
                    <option value='Math Methods'>Math Methods</option>
                    <option value='Math Content'>Math Content</option>
                    <option value='EAMCET'>EAMCET</option>
                </select>
            </div>
            <div className='create-test-form-group'>
                <label htmlFor='test-difficulty' className='create-test-label'>Test Difficulty:</label>
                <select id='test-difficulty' className='create-test-input' name='difficulty' value={testDetailsAndQues.difficulty} onChange={handletestDetailsChange}>
                    <option value='easy'>Easy</option>
                    <option value='medium'>Medium</option>
                    <option value='hard'>Hard</option>
                </select>
            </div>
            <div className='edit-test-con'>
                <Popup
                    trigger={<button className='question-list-button' onClick={() => removeQuestion(question.id)}>Delete Test</button>}
                    modal
                >
                    {close => (
                    <div className="modal">
                        {renderTestDeletePopUp(close)}
                    </div>
                    )}
                </Popup>
                <button className='question-list-button' onClick={updateTest}>
                    {
                    loading ?
                        <Oval
                        visible={true}
                        height="20"
                        width="20"
                        color="#41B06E"
                        strokeWidth="4"
                        ariaLabel="oval-loading"
                        wrapperStyle={{}}
                        secondaryColor="#fff"
                        wrapperClass=""
                        /> : 
                        'Update Test'
                    }
                </button>
            </div>
        </div>

        <ul className='questions-list'>
            {
                testDetailsAndQues.questions.map((question, index) => {
                    return <li key={question.id} className='question-list-item'>
                        <div className='question-list-head-button-con'>
                            <h3 className='question-list-heading'><span className='question-list-heading-span'>{index + 1}. </span> {question.question_text}</h3>
                            <div className='question-list-button-con'>
                                <button className='question-list-button' onClick={() => removeQuestion(question.id)}>Delete</button>
                            </div>
                        </div>
                        <p className='question-list-text'><span className='question-list-text-span'>A: </span> {question.option_a}</p>
                        <p className='question-list-text'><span className='question-list-text-span'>B: </span> {question.option_b}</p>
                        <p className='question-list-text'><span className='question-list-text-span'>C: </span> {question.option_c}</p>
                        <p className='question-list-text'><span className='question-list-text-span'>D: </span> {question.option_d}</p>
                        <p className='question-list-text'><span className='question-list-text-span'>Correct Option: </span> {question.correct_option}</p>
                    </li>
                })
            }
        </ul>
        {questinError && <p className='question-error' style={{marginLeft: "10%"}} >{questinError}</p>}
        <div className='add-question-button-container'>
            <Popup
                trigger={<button className="add-question-button">Add Question</button>}
                modal
            >
                {close => (
                <div className="question-modal">
                    <QuestionForm close={close} handleQuestionChange={handleQuestionChange} question={question} addQuestion={addQuestion} questinError={questinError} />
                </div>
                )}
            </Popup>
            <button className='add-question-button' onClick={updateTest}>
                {
                loading ?
                    <Oval
                    visible={true}
                    height="20"
                    width="20"
                    color="#41B06E"
                    strokeWidth="4"
                    ariaLabel="oval-loading"
                    wrapperStyle={{}}
                    secondaryColor="#fff"
                    wrapperClass=""
                    /> : 
                    'Update Test'
                }
            </button>
        </div>
        </>
    )

    const renderLoading = () => (
        <div className="loading-container">
            <MutatingDots
                visible={true}
                height="100"
                width="100"
                color="#41B06E"
                secondaryColor="#41B06E"
                radius="12.5"
                ariaLabel="mutating-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
            />
        </div>
    )

    const renderFailure = () => (
        <div className="exam-failure-con" style={{margin: 'auto', marginTop: '10vh'}}>
            <p className='exam-failure-text'>Failed to load test details. Please try again later.</p>
            <button className='exam-failure-button' onClick={fetchTestDetails}>Try Again</button>
        </div>
    )

    const renderSwitch = () => {
        switch (apiStatus) {
            case apiStatusConstants.loading:
                return renderLoading();
            case apiStatusConstants.success:
                return renderTests();
            case apiStatusConstants.failure:
                return renderFailure();
            default:
                return null;
        }
    }

    if(Cookies.get('admin') === undefined) return (<Navigate to='/' />);

    return (
        <div className='create-test-page-container'>
            <h1 className='create-test-heading'>Update Test</h1>
            {renderSwitch()}
        </div>
    )
}

export default EditTestPage;