import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import './style.css'

const Login = ({toggleLogin}) => {

    const [error, setError] = useState('');
    const [loginData, setLoginData] = useState({
        email: '',
        password: ''
    });

    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setLoginData({
            ...loginData,
            [name]: value
        });
    }

    const handleLogin = async (e) => {
        e.preventDefault();
        if(!loginData.email || !loginData.password) {
            setError('Please enter all fields');
            return;
        }
        const url = process.env.REACT_APP_API_URL + '/user/login';
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(loginData)
        };

        const response = await fetch(url, options);
        const data = await response.json();
        if(response.ok === true) {
            Cookies.set('jwt_token', data.jwtToken, {expires: 15});
            if(data.admin === 1) {
                Cookies.set('admin', 1, {expires: 15});
            }
            navigate('/', {replace: true});
        } else {
            setError(data.error);
        }
    }

    return (
        <form className='login-form' onSubmit={handleLogin} >
            <h1 className='login-form-heading'>Login</h1>
            <label htmlFor='email' className='login-form-label'>EMAIL</label>
            <input type='email' id='email' className='login-form-input' placeholder='Email' name='email' onChange={handleInputChange} />
            <label htmlFor='password' className='login-form-label' >PASSWORD</label>
            <input type='password' id='password' className='login-form-input' placeholder='Password' name='password' onChange={handleInputChange} />
            {error && <p className='error-message'>{error}</p>}
            <button type='submit' className='login-form-button'>Login</button>
            <p className='login-form-text'>Don't have an account? <span className='login-form-link' onClick={toggleLogin}>Signup</span></p>
        </form>
    );
}

export default Login;