import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import Login from '../Login';
import SignUp from '../SignUp';
import './style.css'

const AuthPage = () => {

    const [showLogin, setShowLogin] = useState(true);

    const toggleLogin = () => {
        setShowLogin(!showLogin);
    }

    const navigate = useNavigate();

    useEffect(() => {
        if(Cookies.get('jwt_token') !== undefined) {
            navigate('/');
        }
    }, []);

    return (
        <div className='home-page-container'>
            <div className='home-page-section'>
                <div className='home-page-content'>
                    {
                        showLogin ?
                        <Login toggleLogin={toggleLogin} /> :
                        <SignUp toggleLogin={toggleLogin} />
                    }
                    
                </div>
                <div className='home-page-image-container'>
                    {/* <img src='/homepage-bg.jpg' alt='placeholder' className='home-page-image' /> */}
                </div>
            </div>
        </div>
    );
}

export default AuthPage;