import Cookies from 'js-cookie';
import { useState } from 'react';
import { RiMenu4Line, RiCloseLine } from "react-icons/ri";
import { Link, useNavigate } from 'react-router-dom';
import './style.css';

const NavBar = () => {

    const [showMobileMenu, setShowMobileMenu] = useState(false);

    const toggleMobileMenu = () => {
        setShowMobileMenu(!showMobileMenu);
    }

    const navigate = useNavigate();

    const handleLogout = () => {
        Cookies.remove('jwt_token');
        Cookies.remove('admin');
        toggleMobileMenu();
        navigate('/');
    }

    return (
        <>
        <nav className="navbar">
            <h1><Link to='/' className='navbar-heading'>HY-Math Academy</Link></h1>
            <ul className="nav-list">
                <li className="nav-item">
                    <Link to="/" className='nav-link'>Home</Link>
                </li>
                <li className="nav-item">
                    <Link to="/tests" className='nav-link'>Tests</Link>
                </li>
                <li className="nav-item">
                    <Link to="/submissions" className='nav-link'>Submissions</Link>
                </li>

                {
                    (Cookies.get('admin') !== undefined) && 
                    <li className="nav-item">
                        <Link to="/admin" className='nav-link'>Admin</Link>
                    </li>
                }

                {
                    (Cookies.get('jwt_token') === undefined) && 
                    <li className="nav-item">
                        <Link to="/auth" className='nav-link'>Login</Link>
                    </li>
                }

                {
                    (Cookies.get('jwt_token') !== undefined) && 
                    <li className="nav-item">
                        <button type='button' className='nav-logout-btn' onClick={handleLogout} >Logout</button>
                    </li>
                }
                
            </ul>
            { showMobileMenu ?
                <button type='button' className="menu-button" aria-label="toggle navigation" onClick={toggleMobileMenu}>
                    <RiCloseLine className='menu-icon' />
                </button>
                :
                <button type='button' className="menu-button" aria-label="toggle navigation" onClick={toggleMobileMenu}>
                    <RiMenu4Line className='menu-icon' />
                </button>
            }
        </nav>
        {
            showMobileMenu &&
            <ul className="nav-list-mobile">
                <li className="nav-item">
                    <Link to="/" className='nav-link' onClick={toggleMobileMenu}>Home</Link>
                </li>
                <li className="nav-item">
                    <Link to="/tests" className='nav-link' onClick={toggleMobileMenu}>Tests</Link>
                </li>
                <li className="nav-item">
                    <Link to="/submissions" className='nav-link' onClick={toggleMobileMenu}>Submissions</Link>
                </li>

                {
                    (Cookies.get('admin') !== undefined) && 
                    <li className="nav-item">
                        <Link to="/admin" className='nav-link' onClick={toggleMobileMenu}>Admin</Link>
                    </li>
                }

                {
                    (Cookies.get('jwt_token') === undefined) && 
                    <li className="nav-item">
                        <Link to="/auth" className='nav-link' onClick={toggleMobileMenu}>Login</Link>
                    </li>
                }

                {
                    (Cookies.get('jwt_token') !== undefined) && 
                    <li className="nav-item">
                        <button type='button' className='nav-logout-btn' onClick={handleLogout} >Logout</button>
                    </li>
                }
            </ul>
        }
        </>
    );
}

export default NavBar;