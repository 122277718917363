
const QuestionForm = ({ handleQuestionChange, question, addQuestion, questinError, close }) => {
    return (
        <div className='create-test-form' style={{border: '0px'}}>
            <button className='close-question-popup' onClick={close}>&times;</button>
            <h2 className='create-test-subheading'>Add Questions</h2>
            <div className='create-test-form-group'>
                <label htmlFor='question-text' className='create-test-label'>Question:</label>
                <textarea id='question-text' className='create-test-textarea' placeholder='your answer' name='question_text' value={question.question_text} onChange={handleQuestionChange} ></textarea>
            </div>
            <div className='create-test-form-group'>
                <label htmlFor='option-a' className='create-test-label'>Option A:</label>
                <input type='text' id='option-a' className='create-test-input' placeholder='your answer' name='option_a' value={question.option_a} onChange={handleQuestionChange}/>
            </div>
            <div className='create-test-form-group'>
                <label htmlFor='option-b' className='create-test-label'>Option B:</label>
                <input type='text' id='option-b' className='create-test-input' placeholder='your answer' name='option_b' value={question.option_b} onChange={handleQuestionChange}/>
            </div>
            <div className='create-test-form-group'>
                <label htmlFor='option-c' className='create-test-label'>Option C:</label>
                <input type='text' id='option-c' className='create-test-input' placeholder='your answer' name='option_c' value={question.option_c} onChange={handleQuestionChange}/>
            </div>
            <div className='create-test-form-group'>
                <label htmlFor='option-d' className='create-test-label'>Option D:</label>
                <input type='text' id='option-d' className='create-test-input' placeholder='your answer' name='option_d' value={question.option_d} onChange={handleQuestionChange}/>
            </div>
            <div className='create-test-form-group'>
                <label htmlFor='correct-option' className='create-test-label'>Correct Option:</label>
                <select id='correct-option' className='create-test-input' name='correct_option' value={question.correct_option} onChange={handleQuestionChange}>
                    <option value='A'>Option A</option>
                    <option value='B'>Option B</option>
                    <option value='C'>Option C</option>
                    <option value='D'>Option D</option>
                </select>
            </div>
            {questinError && <p className='question-error'>{questinError}</p>}
            <button className='add-question-button' onClick={() => addQuestion(close)}>Add</button>
        </div>
    )
}

export default QuestionForm;