import { useState } from "react";

const SignUp = ({toggleLogin}) => {

    const [error, setError] = useState('');
    const [signupData, setSignupData] = useState({
        username: '',
        email: '',
        phone: '',
        password: ''
    });

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setSignupData({
            ...signupData,
            [name]: value
        });
    }

    const handleSignup = async (e) => {
        e.preventDefault();
        if(!signupData.username || !signupData.email || !signupData.phone || !signupData.password) {
            setError('Please enter all fields');
            return;
        }
        const url = process.env.REACT_APP_API_URL + '/user/register';
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(signupData)
        };

        const response = await fetch(url, options);
        const data = await response.json();
        if(response.ok === true) {
            toggleLogin();
        } else {
            setError(data.error);
        }
    }

    return (
        <form className='login-form' onSubmit={handleSignup}>
            <h1 className='login-form-heading'>Sign up</h1>
            <label htmlFor='username' className='login-form-label'>USERNAME</label>
            <input type='text' id='username' className='login-form-input' placeholder='Username' name='username' onChange={handleInputChange} />
            <label htmlFor='email' className='login-form-label'>EMAIL</label>
            <input type='email' id='email' className='login-form-input' placeholder='Email' name='email' onChange={handleInputChange} />
            <label htmlFor='phone' className='login-form-label'>PHONE</label>
            <input type='number' id='phone' className='login-form-input' placeholder='Phone' name='phone' onChange={handleInputChange} />
            <label htmlFor='password' className='login-form-label' >PASSWORD</label>
            <input type='password' id='password' className='login-form-input' placeholder='Password' name='password' onChange={handleInputChange} />
            { error && <p className='error-message'>{error}</p> }
            <button type='submit' className='login-form-button'>Sign up</button>
            <p className='login-form-text'>Already have an account? <span className='login-form-link' onClick={toggleLogin}>Login</span></p>
        </form>
    );
}

export default SignUp;