import { IoSearchSharp } from 'react-icons/io5';
import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { MutatingDots } from 'react-loader-spinner';
import { Navigate } from 'react-router-dom';
import UsersItem from '../UserItem';
import './style.css'

const apiStatusConstants = {
    initial: 'INITIAL',
    loading: 'LOADING',
    success: 'SUCCESS',
    failure: 'FAILURE'
}

const ViewUsers = () => {

    const [searchInput, setSearchInput] = useState('');
    const [blockStatus, setBlockStatus] = useState(false);
    const [users, setUsers] = useState([]);
    const [apiStatus, setApiStatus] = useState(apiStatusConstants.initial);
    const [passwordDetails, setPasswordDetails] = useState({
        password: '',
        confirmPassword: ''
    });

    const backendUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        setApiStatus(apiStatusConstants.loading);
        const url = backendUrl + '/admin/users';
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${Cookies.get('jwt_token')}`
            }
        }
        try {
            const response = await fetch(url, options);
            const data = await response.json();
            if(response.ok) {
                setUsers(data);
                setApiStatus(apiStatusConstants.success);
            } else {
                setApiStatus(apiStatusConstants.failure);
            }

        } catch (error) {
            setApiStatus(apiStatusConstants.failure);
        }
    }

    const handleChangeSearchInput = (e) => {
        setSearchInput(e.target.value);
    }

    const handleChangePasswordDetails = (event) => {
        setPasswordDetails({...passwordDetails, [event.target.name]: event.target.value});
    }

    const grantCourseAccess = async (close, email) => {
        setBlockStatus(true);
        const url = `${backendUrl}/admin/give-course-access/${email}`;
        const options = {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: `Bearer ${Cookies.get('jwt_token')}`
            }
        };
        try {
            const response = await fetch(url, options);
            const data = await response.json();
            if(response.ok === true) {
                if(data.error) {
                    alert(data.error);
                } else {
                    setUsers(users.map(eachItem => {
                        if(eachItem.email === email) {
                            return {...eachItem, course_access: 1}
                        } else {
                            return eachItem;
                        }
                    }))
                    close();
                }
            }
        } catch (error) {
            alert(error);
        }
        setBlockStatus(false);
    }

    const removeCourseAccess = async (close, email) => {
        setBlockStatus(true);
        const url = `${backendUrl}/admin/remove-course-access/${email}`;
        const options = {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json',
                Authorization: `Bearer ${Cookies.get('jwt_token')}`
            }
        };
        try {
            const response = await fetch(url, options);
            const data = await response.json();
            if(response.ok === true) {
                if(data.error) {
                    alert(data.error);
                } else {
                    setUsers(users.map(eachItem => {
                        if(eachItem.email === email) {
                            return {...eachItem, course_access: 0}
                        } else {
                            return eachItem;
                        }
                    }))
                    close();
                }
            }
        } catch (error) {
            alert(error);
        }
        setBlockStatus(false);
    }

    const changePassword = async (close, email) => {
        if(passwordDetails.password.trim().length < 6) {
            alert('Password should be atleast 6 characters long');
            return;
        } else if(passwordDetails.password !== passwordDetails.confirmPassword) {
            alert('Passwords do not match');
            return;
        }
        const url = `${backendUrl}/admin/change-password`;
        const options = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${Cookies.get('jwt_token')}`
            },
            body: JSON.stringify({email, password: passwordDetails.password})
        };
        try {
            const response = await fetch(url, options);
            const data = await response.json();
            if(response.ok === true) {
                if(data.error) {
                    alert(data.error);
                } else {
                    setPasswordDetails({
                        password: '',
                        confirmPassword: ''
                    });
                    close();
                }
            } else {
                alert(data.error);
            }
        } catch (error) {
            alert(error);
        }
    }

    
    const renderCourseAccessPopUp = (close, email, courseAccess) => (
        <div className="modal-form">
            <button className="modal-close-button" disabled={blockStatus} onClick={close}>&times;</button>
            <p className="modal-text">Do you want to {courseAccess === 0 ? "Grant" : "Remove"} course access to this user?</p>
            <div className='achieve-button-con'>
            {
                courseAccess === 0 ?
                <button className='job-details-upload-candidate-button' disabled={blockStatus} onClick={() => grantCourseAccess(close, email)}>YES</button>
                :
                <button className='job-details-upload-candidate-button' disabled={blockStatus} onClick={() => removeCourseAccess(close, email)}>YES</button>
            }
            <button className='job-details-upload-candidate-button archieve-cancel-btn' disabled={blockStatus} onClick={close}>NO</button>
            </div>
        </div>
    )

    const renderChangePasswordPopup = (close, email) => (
        <div className="modal-form">
            <button className="modal-close-button" onClick={close}>&times;</button>
            <p className="modal-text" style={{marginBottom: '15px'}}>Change password for {email}</p>
            <p className="user-label">Enter new password</p>
            <input className="user-input" type="password" name='password' onChange={handleChangePasswordDetails} />
            <label className="user-label">Confirm new password</label>
            <input className="user-input" type="password" name='confirmPassword' onChange={handleChangePasswordDetails}/>
            <div className='achieve-button-con' style={{marginTop: '0px'}}>
                <button className='job-details-upload-candidate-button' onClick={() => changePassword(close, email)}>Change</button>
                <button className='job-details-upload-candidate-button archieve-cancel-btn' onClick={close}>Cancel</button>
            </div>
        </div>
    )

    const renderGetUsers = () => { 
        const filteredUsers = users.filter(eachItem => 
            eachItem.username.toLowerCase().includes(searchInput.toLowerCase()) || 
            eachItem.email.toLowerCase().includes(searchInput.toLowerCase()) ||
            eachItem.phone.toLowerCase().includes(searchInput.toLowerCase())
        );
        return (
            <>
            <div className="user-view-search-filter-con">
                <div className="user-view-search-con">
                    <div className="user-view-search-button">
                        <IoSearchSharp className="search-icon" />
                    </div>
                    <input className="user-view-search-input" type="search" value={searchInput} onChange={handleChangeSearchInput} placeholder="Search by name, email, or phone" />
                </div>
            </div>
            <div className="user-view-table">
                <table className="users-table">
                        <tr className="users-table-heading-row">
                            <th className="users-table-heading">Username</th>
                            <th className="users-table-heading">Email</th>
                            <th className="users-table-heading">Phone</th>
                            <th className="users-table-heading">Created At</th>
                            <th className="users-table-heading">Course Access</th>
                            <th className="users-table-heading">Change Password</th>
                        </tr>
                        {
                            filteredUsers.map(eachItem => (
                                <UsersItem key={eachItem.email} userDetails={eachItem} renderCourseAccessPopUp={renderCourseAccessPopUp} renderChangePasswordPopup={renderChangePasswordPopup} />
                        ))}
                </table>
                {
                    filteredUsers.length === 0 && 
                    <p className='user-view-table-no-data'>No data available</p>
                }
            </div>
            </>
        )
    }

    const renderNoUsers = () => (
        <div className='no-submissions-con'>
            <p className='no-submissions-text'>No users available</p>
        </div>
    )

    const renderLoading = () => (
        <div className="loading-container">
            <MutatingDots
                visible={true}
                height="100"
                width="100"
                color="#41B06E"
                secondaryColor="#41B06E"
                radius="12.5"
                ariaLabel="mutating-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
            />
        </div>
    )

    const renderFailure = () => (
        <div className="exam-failure-con">
            <p className='exam-failure-text'>Failed to load the page. Please try again later.</p>
            <button className='exam-failure-button' onClick={fetchUsers}>Try Again</button>
        </div>
    )

    const renderSwitch = () => {
        switch (apiStatus) {
            case apiStatusConstants.loading:
                return renderLoading();
            case apiStatusConstants.success:
                return users.length > 0 ? renderGetUsers() : renderNoUsers();
            case apiStatusConstants.failure:
                return renderFailure();
            default:
                return null;
        }
    }

    if(Cookies.get('admin') === undefined) return (<Navigate to='/' />);

    return (
        <div className='view-users-con'>
            <h1 className='user-heading'>Users View</h1>
            {renderSwitch()}
        </div>
    );
}

export default ViewUsers;