import { Routes, Route, Navigate } from 'react-router-dom';
import NavBar from './components/NavBar';
import TestsPage from './components/TestsPage';
import AuthPage from './components/AuthPage';
import HomePage from './components/HomePage';
import TestDetailsPage from './components/TestDetailsPage';
import AdminPage from './components/AdminPage';
import SubmissionsPage from './components/SubmissionsPage';
import ExamPage from './components/ExamPage';
import ViewUsers from './components/ViewUsers';
import AdminTestsPage from './components/AdminTestsPage';
import CreateTestPage from './components/CreateTestPage';
import EditTestPage from './components/EditTestPage';
import AdminSubmissionsPage from './components/AdminSubmissionsPage';
import ControlHomepage from './components/ControlHomepage';
import ProtectedRoute from './components/ProtectedRoute';
import './App.css';
import Footer from './components/Footer';

function App() {
  return (
    <>
      <NavBar />
      <Routes>
        <Route path='/' exact element={<HomePage />} />
        <Route path='/auth' exact element={<AuthPage />} />
        <Route 
          path='/tests' 
          exact
          element={<ProtectedRoute element={<TestsPage />} />} 
        />
        <Route 
          path='/tests/:id' 
          exact
          element={<ProtectedRoute  element={<TestDetailsPage />} />} 
        />
        <Route 
          path='/exam/:id' 
          exact
          element={<ProtectedRoute  element={<ExamPage />} />} 
        />
        <Route 
          path='/submissions' 
          exact
          element={<ProtectedRoute  element={<SubmissionsPage />} />} 
        />
        <Route 
          path='/admin' 
          exact
          element={<ProtectedRoute  element={<AdminPage />} />} 
        />
        <Route 
          path='/admin/users' 
          exact
          element={<ProtectedRoute  element={<ViewUsers />} />} 
        />
        <Route 
          path='/admin/tests' 
          exact
          element={<ProtectedRoute  element={<AdminTestsPage />} />} 
        />
        <Route 
          path='/admin/test/new' 
          exact
          element={<ProtectedRoute  element={<CreateTestPage />} />} 
        />
        <Route 
          path='/admin/tests/:id' 
          exact
          element={<ProtectedRoute  element={<EditTestPage />} />} 
        />
        <Route 
          path='/admin/submissions' 
          exact
          element={<ProtectedRoute  element={<AdminSubmissionsPage />} />} 
        />
        <Route 
          path='/admin/faculty' 
          exact
          element={<ProtectedRoute  element={<ControlHomepage />} />} 
        />
        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
