import './style.css'

const Footer = () => {
    return (
        <footer className='footer'>
            <p className='footer-text'>&copy; 2024 HY-Math Academy</p>
            <p className='footer-text'>All Rights Reserved</p>
        </footer>
    )
}

export default Footer;