import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { MutatingDots } from 'react-loader-spinner';
import { MdCancel } from "react-icons/md";
import withPageLeaveConfirmation from './withPageLeaveConfirmation';

import './style.css'

const apiStatusConstants = {
    initial: 'INITIAL',
    loading: 'LOADING',
    success: 'SUCCESS',
    failure: 'FAILURE'
}

const ExamPage = () => {
    
        const [examDetails, setExamDetails] = useState({});
        const [examQuestions, setExamQuestions] = useState([]);
        const [examAnswers, setExamAnswers] = useState([]);
        const [examSubmitted, setExamSubmitted] = useState(false);
        const [examResult, setExamResult] = useState({});
        const [currentQuestion, setCurrentQuestion] = useState(0);
        const [currentQuestionAnswer, setCurrentQuestionAnswer] = useState('');
        const [minutes, setMinutes] = useState(0);
        const [seconds, setSeconds] = useState(59);
        const [apiStatus, setApiStatus] = useState(apiStatusConstants.initial);
        const [showSubmitPopUP, setShowSubmitPopUP] = useState(false);
        const [showTimerUpPopup, setShowTimerUpPopup] = useState(false);
        const [isFullScreenPopupVisible, setIsFullScreenPopupVisible] = useState(false);
        const [fullscreenCount, setFullscreenCount] = useState(0);
        const [isFullscreen, setIsFullscreen] = useState(document.fullscreenElement);
        const [hasEnteredFullscreen, setHasEnteredFullscreen] = useState(false);
        const [isPageVisible, setIsPageVisible] = useState(!document.hidden);

        useEffect(() => {
        const handleFullscreenChange = () => {
            const fullscreenElement = !!document.fullscreenElement;
            setIsFullscreen(fullscreenElement);
            if (fullscreenElement) {
            setHasEnteredFullscreen(true);
            }
        };

        const handleVisibilityChange = () => {
            setIsPageVisible(!document.hidden);
        };

        document.addEventListener('fullscreenchange', handleFullscreenChange);
        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
        }, []);

        useEffect(() => {
            if (hasEnteredFullscreen && (!isFullscreen || !isPageVisible) && examSubmitted === false) {
                if (fullscreenCount === 3) {
                    setShowTimerUpPopup(true);
                    return
                }
                setIsFullScreenPopupVisible(true);
                setFullscreenCount(fullscreenCount + 1);
            }
        }, [isFullscreen, isPageVisible, hasEnteredFullscreen]);

        const handleFullScreen = () => {
            const element = document.documentElement;
            if (document.fullscreenElement) {
                document.exitFullscreen();
            } else {
                element.requestFullscreen();
            }
            setHasEnteredFullscreen(true);
            setIsFullScreenPopupVisible(false);
        }
        
        useEffect(() => {
            getExamDetails();
            checkExamAccess();
        }, []);

        const startTime = useRef(Date.now());
        const endTime = useRef(startTime.current + examDetails.duration_mins * 60 * 1000);
        let timerId = useRef(null);

        useEffect(() => {
            if (Object.keys(examDetails).length !== 0 && typeof examDetails.duration_mins === 'number') {
                const startTime = Date.now();
                const endTime = startTime + examDetails.duration_mins * 60 * 1000;
                timerId.current = setInterval(() => {
                    const remainingTime = endTime - Date.now();
                    if (remainingTime <= 0) {
                        clearInterval(timerId.current);
                        // submitExam(); 
                        setShowTimerUpPopup(true);
                    } else {
                        const remainingMinutes = Math.floor(remainingTime / 60000);
                        const remainingSeconds = Math.floor((remainingTime % 60000) / 1000);
                        setMinutes(remainingMinutes);
                        setSeconds(remainingSeconds);
                    }
                }, 1000);
            }
            return () => clearInterval(timerId.current);
        }, [examDetails]);

        
        const { id } = useParams();
        const navigate = useNavigate();
    
        const getExamDetails = async () => {
            setApiStatus(apiStatusConstants.loading);
            try {
                const url = process.env.REACT_APP_API_URL + `/exam/questions/${id}`;
                const options = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${Cookies.get('jwt_token')}`
                    }
                }
                const response = await fetch(url, options);
                const data = await response.json();
                if (response.ok === true) {
                    setExamDetails(data.testDetails);
                    setExamQuestions(data.questions);
                    setApiStatus(apiStatusConstants.success);
                } else {
                    alert(data.error);
                    setApiStatus(apiStatusConstants.failure);
                }
            }
            catch (error) {
                setApiStatus(apiStatusConstants.failure);
            }
        }
    
        const checkExamAccess = async () => {
            try {
                const url = process.env.REACT_APP_API_URL + '/exam/access';
                const options = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${Cookies.get('jwt_token')}`
                    }
                }
                const response = await fetch(url, options);
                const data = await response.json();
                if (response.ok === true) {
                    if (data.access) {
                        return true
                    } else {
                        navigate(`/tests`);
                    }
                } else {
                    alert(data.message);
                }
            } catch (error) {
                alert(error);
            }
        }
    


        const submitExam = async () => {
            clearInterval(timerId.current);
            const score = examAnswers.reduce((acc, answer) => acc + answer.score, 0); // Calculate score here
            try {
                const url = process.env.REACT_APP_API_URL + '/exam/submit/' + id;
                const options = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${Cookies.get('jwt_token')}`
                    },
                    body: JSON.stringify({
                        score: score,
                        totalScore: examQuestions.length
                    })
                }
                setApiStatus(apiStatusConstants.loading);
                const response = await fetch(url, options);
                const data = await response.json();
                if (response.ok === true) {
                    setExamSubmitted(true);
                    setExamResult(data);
                    setApiStatus(apiStatusConstants.success);
                    setShowTimerUpPopup(false);
                    if (document.fullscreenElement) {
                        document.exitFullscreen();
                    }
                } else {
                    alert(data.error);
                    setApiStatus(apiStatusConstants.failure);
                }
            } catch (error) {
                alert(error);
                setApiStatus(apiStatusConstants.failure);
            }
        }

        const handleAnswerChange = (questionId, correctAnswer, ans) => {
            setCurrentQuestionAnswer(ans);
            if(examAnswers.some(answer => answer.question_id === questionId)) {
                const newAnswers = examAnswers.map(answer => {
                    if (answer.question_id === questionId) {
                        let score = 0;
                        if(ans === correctAnswer) {
                            score = 1;
                        }
                        return {
                            question_id: questionId,
                            answer: ans,
                            score: score
                        }
                    }
                    return answer;
                });
                setExamAnswers(newAnswers);
            } else {
                let score = 0;
                if(ans === correctAnswer) {
                    score = 1;
                }
                setExamAnswers([...examAnswers, {question_id: questionId, answer: ans, score: score}]);
            }
        }

        const getCurrentQuestionAnswer = (index) => {
            const questionId = examQuestions[index].id;
            const ans = examAnswers.find(answer => answer.question_id === questionId);
            if(ans) {
                setCurrentQuestionAnswer(ans.answer);
            } else {
                setCurrentQuestionAnswer('');
            }
        }

        const handleNextQuestion = () => {
            if(currentQuestion < examQuestions.length - 1) {
                setCurrentQuestion(currentQuestion => currentQuestion + 1);
                getCurrentQuestionAnswer(currentQuestion + 1);
            }
        }

        const handlePreviousQuestion = () => {
            if(currentQuestion > 0) {
                setCurrentQuestion(currentQuestion => currentQuestion - 1);
                getCurrentQuestionAnswer(currentQuestion - 1);
            }
        }

        const renderFullScreenPopup = () => {
            return (
                <div className="timer-up-popup">
                    <div className="timer-up-popup-overlay" ></div>
                    <div className="timer-up-popup-content">
                        <p className='dashboard-text' style={{marginBottom: "0px"}}>You have exited fullscreen mode or switched to another app.</p>
                        <p className='dashboard-text' style={{marginTop: "15px"}}>You are exited fullscreen mode {fullscreenCount} times, only {3 - fullscreenCount} more time is allowed, after that the exam will be submitted.</p>
                        <button className='payment-popup-button' style={{marginTop: "15px"}} onClick={handleFullScreen}>Close</button>
                    </div>
                </div>
            )
        }

        const renderTimerUpPopup = () => {
            return (
                <div className="timer-up-popup">
                    <div className="timer-up-popup-overlay" ></div>
                    <div className="timer-up-popup-content">
                        <p className='dashboard-text' style={{marginBottom: "0px"}}>{fullscreenCount === 3 ? "You're exceeded the fullscreen mode exit limit." : "Time is up!"}</p>
                        <p className='dashboard-text' style={{marginTop: "15px"}}>Click on submit to end the exam.</p>
                        <button className='payment-popup-button' style={{marginTop: "15px"}} onClick={submitExam}>Submit</button>
                    </div>
                </div>
            )
        }

        const renderSubmitPopup = () => {
            return (
                <div className="submit-popup">
                    <div className="submit-popup-overlay" onClick={() => setShowSubmitPopUP(false)}></div>
                    <div className="submit-popup-content">
                        <button className='close-button' onClick={() => setShowSubmitPopUP(false)}>&times;</button>
                        <p className='dashboard-text' style={{marginBottom: "0px"}}>Do you want to submit the exam?</p>
                        {
                            examQuestions.length !== examAnswers.length && <p className='dashboard-text' style={{marginTop: "15px"}}>You have not attempted all the questions, are you sure you want to submit?</p>
                        }
                        <div className='payment-popup-buttons'>
                            <button className='payment-popup-button' onClick={() => setShowSubmitPopUP(false)}>Cancel</button>
                            <button className='payment-popup-button' onClick={submitExam}>Submit</button>
                        </div>
                    </div>
                </div>
            )
        }

        const renderQuestions = () => (
            <>
            <div className="exam-header">
                <h1 className='exam-title'>{examDetails.title}</h1>
                <p className='exam-time'>Time Left: {minutes < 10 ? "0"+minutes : minutes}:{seconds < 10 ? "0"+seconds : seconds} </p>
                <div className="exam-questions-attempt-con">
                    <h3 className="exam-questions-attempted">QUESTIONS ATTEMPTED: {examAnswers.length}/{examQuestions.length}</h3>
                    <button className='exam-end-button' onClick={() => setShowSubmitPopUP(true)}>
                        <MdCancel className='exam-end-icon' />
                        <span className='exam-end-text' >END EXAM</span>
                    </button>
                </div>
            </div>
            <p className='exam-time-mobile'>Time Left: {minutes < 10 ? "0"+minutes : minutes}:{seconds < 10 ? "0"+seconds : seconds} </p>
            <ul className="exam-questions-list">
                <li className='exam-question'>
                    <p className='exam-question-text'>{examQuestions[currentQuestion].question_text}</p>
                    <hr className='line' />
                    <ul className='exam-answers'>
                        <li className='exam-answer'>
                            <input type='radio' name='answer' id='answer1' className='answer' value="A" onChange={() => handleAnswerChange(examQuestions[currentQuestion].id, examQuestions[currentQuestion].correct_option, "A")} checked={currentQuestionAnswer === 'A'} />
                            <label htmlFor='answer1' className='answer-label'>{examQuestions[currentQuestion].option_a}</label>
                        </li>
                        <li className='exam-answer'>
                            <input type='radio' name='answer' id='answer2' className='answer' value="B" onChange={() => handleAnswerChange(examQuestions[currentQuestion].id, examQuestions[currentQuestion].correct_option, "B")} checked={currentQuestionAnswer === 'B'} />
                            <label htmlFor='answer2' className='answer-label'>{examQuestions[currentQuestion].option_b}</label>
                        </li>
                        <li className='exam-answer'>
                            <input type='radio' name='answer' id='answer3' className='answer' value="C" onChange={() => handleAnswerChange(examQuestions[currentQuestion].id, examQuestions[currentQuestion].correct_option, "C")} checked={currentQuestionAnswer === 'C'} />
                            <label htmlFor='answer3' className='answer-label'>{examQuestions[currentQuestion].option_c}</label>
                        </li>
                        <li className='exam-answer'>
                            <input type='radio' name='answer' id='answer4' className='answer' value="D" onChange={() => handleAnswerChange(examQuestions[currentQuestion].id, examQuestions[currentQuestion].correct_option, "D")} checked={currentQuestionAnswer === 'D'} />
                            <label htmlFor='answer4' className='answer-label'>{examQuestions[currentQuestion].option_d}</label>
                        </li>
                    </ul>
                </li>
                <div className='exam-question-buttons-con'>
                    {
                        (currentQuestion > 0 && <button className='exam-question-button' onClick={handlePreviousQuestion}>Previous</button>)
                    }
                    {
                        (currentQuestion === examQuestions.length - 1 ? 
                        <button className='exam-question-button' onClick={() => setShowSubmitPopUP(true)}>Submit</button> : 
                        <button className='exam-question-button' onClick={handleNextQuestion}>Next</button>)
                    }
                </div>
                {showSubmitPopUP && renderSubmitPopup()}
            </ul>
            </>
        )

        const renderResult = () => (
            <div className="exam-result-con">
                <h1 className='exam-title'>{examDetails.title}</h1>
                <p className='exam-result-text'>You have completed the exam. Your score is {examResult.score} out of {examResult.total_score}.</p>
                <button className='exam-result-button' onClick={() => navigate('/tests', {replace: true})}>Go to Tests</button>
            </div>
        )

        const renderLoading = () => (
            <div className="loading-container">
                <MutatingDots
                    visible={true}
                    height="100"
                    width="100"
                    color="#41B06E"
                    secondaryColor="#41B06E"
                    radius="12.5"
                    ariaLabel="mutating-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
            </div>
        )

        const renderFailure = () => (
            <div className="exam-failure-con">
                <p className='exam-failure-text'>Failed to load exam details. Please try again later.</p>
                <button className='exam-failure-button' onClick={getExamDetails}>Try Again</button>
            </div>
        )

        const renderSwitch = () => {
            switch (apiStatus) {
                case apiStatusConstants.loading:
                    return renderLoading();
                case apiStatusConstants.success:
                    return examSubmitted ? renderResult() : renderQuestions();
                case apiStatusConstants.failure:
                    return renderFailure();
                default:
                    return null;
            }
        }

        return (
            <div className="exam-page-container">
                {renderSwitch()}
                {showTimerUpPopup && renderTimerUpPopup()}
                {isFullScreenPopupVisible && renderFullScreenPopup()}
            </div>
        );

}

export default withPageLeaveConfirmation(ExamPage);